<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <v-row>
          <v-col cols="7" md="7">
            <PageHeader :title="title" />
          </v-col>
        </v-row>
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-row class="pr-4">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Tipo expediente -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      v-model="tiposExpSelected"
                      :items="itemsTipoExp"
                      label="Tipo de expediente"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      clearable
                      return-object
                      @change="handleTipoExpChange"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- sector -->
                  <v-col cols="6" sm="6" md="4" class="py-0">
                    <v-autocomplete
                      v-model="sectorSelected"
                      autocomplete="on"
                      :items="itemsSector"
                      label="Sector"
                      item-text="sectorNom"
                      clearable
                      item-value="sectorId"
                      return-object
                      outlined
                      dense
                      @change="handleSectorChange"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      :disabled="!isFormValid"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsConfigRepli"
            item-key="nroProceso"
            :loading="loadingTable"
            class="elevation-1"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="canConfig" cols="2">
                    <v-btn color="primary" @click="goToConfigProceso()">
                      Fecha contable
                    </v-btn>
                  </v-col>
                  <v-col v-else cols="2"></v-col>
                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModal()"
                      align="end"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModal(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar configuración</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteProceso(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar configuración</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <DeleteDialog
            :titleProp="titleDelete"
            :maxWidth="'30%'"
            :isLoading="loadingBtnDelete"
            :openDelete.sync="showDeleteModal"
            @onDeleteItem="confirmDelete()"
          />
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-9 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalProc"
      v-model="openModalProc"
      max-width="38%"
      @keydown.esc="closeAndReloadNew"
      persistent
    >
      <NuevaConfigProceso
        :itemsConsulta="itemsConsulta"
        @closeAndReloadNew="closeAndReloadNew"
      ></NuevaConfigProceso>
    </v-dialog>
    <v-dialog
      v-if="openModalConfigProc"
      v-model="openModalConfigProc"
      max-width="35%"
      @keydown.esc="closeAndReloadConfig"
      persistent
    >
      <ConfigProceso
        @closeAndReloadConfig="closeAndReloadConfig"
      ></ConfigProceso>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Ayuda from "@/components/shared/Ayuda.vue";
import NuevaConfigProceso from "@/components/modules/administracion-sistema/NuevaConfigProceso.vue";
import ConfigProceso from "@/components/modules/administracion-sistema/ConfigProceso.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfigReplicacion",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    DeleteDialog,
    Ayuda,
    GoBackBtn,
    ConfigProceso,
    NuevaConfigProceso
  },
  data() {
    return {
      sectorSelected: 0,
      itemsSector: [],
      tiposExpSelected: 0,
      itemsTipoExp: [],
      title: "Configuración de proceso de replicación",
      settingsIcon: enums.icons.SETTINGS,
      search: "",
      routeToGo: "ConfiguracionMesaEntrada",
      showHelp: false,
      optionCode: enums.webSiteOptions.CONFIGURACION_REPLICACION,
      showIcon: true,
      searchIcon: enums.icons.SEARCH,
      seeIcon: enums.icons.SEE,
      showFilters: true,
      isFormValid: true,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      showExpand: false,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      rules: rules,
      allowedActions: null,
      canDelete: false,
      canCreate: false,
      canEdit: false,
      filtersApplyed: [],
      headers: [
        {
          text: "Tipo de expediente",
          align: "start",
          sortable: true,
          value: "tipoExpNom"
        },
        {
          text: "Sector",
          sortable: true,
          value: "sectorNom"
        },
        {
          text: "Proceso",
          sortable: true,
          value: "procNombre"
        },
        {
          text: "Evento",
          sortable: true,
          value: "evento"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end",
          width: "5%"
        }
      ],
      itemsConfigRepli: [],
      loadingTable: false,
      titleDelete: "¿Desea eliminar la configuración del proceso?",
      showDeleteModal: false,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      canConfig: false,
      mSCloseBoxIcon: enums.icons.CLOSE_BOX,
      checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
      loadingBtnDelete: false,
      openModalConfigProc: false,
      modalDetalleProcMasivoOP: false,
      detalleProcesoMasivoOP: null,
      itemAnulado: false,
      openModalProc: false
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSector();
    this.setTipoExp();
    this.applyFilters();
  },
  methods: {
    ...mapActions({
      getSectores: "AdministracionSistema/getSectores",
      getTipoExp: "AdministracionSistema/getTipoExp",
      getConfigProceso: "AdministracionSistema/getConfigProceso",
      deleteProcTipoExp: "AdministracionSistema/deleteProcTipoExp",
      setAlert: "user/setAlert"
    }),
    async setSector() {
      const res = await this.getSectores();
      this.itemsSector = res;
    },

    async setTipoExp() {
      const resp = await this.getTipoExp();
      this.itemsTipoExp = resp;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.CONFIGURACION_REPLICA:
            this.canConfig = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVA_CONFIG_REPLICA:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONFIG_REPLICA:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_CONFIG_REPLICA:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    handleTipoExpChange(newValue) {
      if (newValue === undefined) {
        this.tiposExpSelected = 0;
      }
    },
    handleSectorChange(newValue) {
      if (newValue === undefined) {
        this.sectorSelected = 0;
      }
    },
    async applyFilters() {
      try {
        this.loadingTable = true;
        this.showFilters = false;
        this.customizeFiltersApplied();

        const filters = {
          mTipoExpId: this.tiposExpSelected ? this.tiposExpSelected.id : 0,
          mSectorId: this.sectorSelected ? this.sectorSelected.sectorId : 0
        };

        const response = await this.getConfigProceso(filters);
        this.itemsConfigRepli = response.data.data;
        this.loadingTable = false;
      } catch {
        this.loadingTable = false;
      }
    },
    customizeFiltersApplied() {
      // const allTodos= "Todos"
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplyed = [];
      if (
        this.tiposExpSelected.id === undefined &&
        this.sectorSelected.sectorId === undefined
      ) {
        this.filtersApplyed.splice(1, 1, {
          key: "tiposExpSelected",
          label: "Filtros",
          model: "Todos"
        });
      } else {
        if (this.tiposExpSelected) {
          this.filtersApplyed.splice(2, 1, {
            key: "tiposExpSelected.id",
            label: "Tipo de expediente",
            model: this.tiposExpSelected.value
          });
        }
        if (this.sectorSelected) {
          this.filtersApplyed.splice(3, 1, {
            key: "sectorSelected",
            label: "Sector seleccionado",
            model: this.sectorSelected.sectorNom
          });
        }
      }
    },
    deleteProceso(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.procTipoExpId;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      try {
        const response = await this.deleteProcTipoExp(this.idToDelete);
        if (response.status === 200) {
          this.loadingBtnDelete = false;
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "El proceso ha sido eliminado con éxito."
          });
          this.applyFilters();
        }
      } catch (error) {
        this.showDeleteModal = false;
      }
    },
    closeAndReloadConfig() {
      this.openModalConfigProc = false;
    },
    goToConfigProceso() {
      this.openModalConfigProc = true;
    },
    openModal(item) {
      this.openModalProc = true;
      this.itemsConsulta = item;
    },
    closeAndReloadNew() {
      this.openModalProc = false;
      this.applyFilters();
    },
    resetForm() {
      this.tiposExpSelected = 0;
      this.sectorSelected = 0;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped></style>
