<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <!-- Tipo expediente -->
            <v-col cols="6" md="6" class="pb-0">
              <v-autocomplete
                v-model="tiposExpSelected"
                :items="itemsTipoExp"
                label="Tipo de expediente"
                :rules="rules.required"
                return-object
                item-text="mTipoExpNom"
                item-value="mTipoExpId"
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
            <!-- sector -->
            <v-col cols="6" md="6" class="pb-0">
              <v-autocomplete
                v-model="sectorSelected"
                autocomplete="on"
                :items="itemsSector"
                label="Sector"
                :rules="rules.required"
                item-text="sectorNom"
                item-value="sectorId"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <!-- proceso -->
          <v-row>
            <v-col cols="12" md="12" class="pb-0">
              <v-autocomplete
                v-model="procesoSelected"
                autocomplete="on"
                :items="itemsProceso"
                label="Proceso"
                :rules="rules.required"
                item-text="value"
                item-value="id"
                return-object
                outlined
                dense
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-radio-group
              v-model="emisionRecepcion"
              mandatory
              class="py-0 ml-5"
            >
              <v-radio class="py-0" label="Emisión" value="E"></v-radio>
              <v-radio class="py-0" label="Recepción" value="R"></v-radio>
              <v-radio
                class="py-0"
                label="Al guardar el expediente"
                value="X"
              ></v-radio>
            </v-radio-group>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="
            !tiposExpSelected || this.tiposExpSelected.length == 0 ||
              !sectorSelected || this.sectorSelected.length == 0 ||
              !procesoSelected || this.procesoSelected.length == 0 || isFormValid == false
          "
          form="form"
          :loading="isLoadingBtn"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "NuevaConfigProceso",
  props: {
    itemsConsulta: {
      type: Object
    }
  },
  data: () => ({
    formEditTitle: "",
    emisionRecepcion: null,
    rules: rules,
    isLoadingBtn: false,
    emision: false,
    recepcion: false,
    guardarExpediente: false,
    sectorSelected: [],
    sectorOriginal: null,
    itemsSector: [],
    tiposExpSelected: [],
    itemsTipoExp: [],
    procesoSelected: [],
    itemsProceso: [],
    settingsIcon: enums.icons.SETTINGS,
    isFormValid: false,
    tiposNomenclador: [],
    tipoNomencladorSelected: null,
    nombreTipoPrestador: null,
    grabarPrecio: false,
    idProcTipo: null
  }),
  created() {
    if (this.itemsConsulta) {
      this.idProcTipo = this.itemsConsulta.procTipoExpId;
      this.setConfigRepli();
    } else {
      this.newConfig();
    }
    this.setSector();
    this.setTipoExp();
    this.setProceso();
  },
  methods: {
    ...mapActions({
      getProcTipoExpById: "AdministracionSistema/getProcTipoExpById",
      getSectores: "AdministracionSistema/getSectores",
      getProcesoReplica: "AdministracionSistema/getProcesoReplica",
      getTipoExpById: "AdministracionSistema/getTipoExpById",
      saveProcTipoExp: "AdministracionSistema/saveProcTipoExp",
      getProcesos: "AdministracionSistema/getProcesos",
      saveTipoPrestador: "prestadores/saveTipoPrestador",
      getNomFarmacia: "prestadores/getNomFarmacia",
      setAlert: "user/setAlert"
    }),
    emisionChange() {
      if (this.emision == true) {
        this.recepcion == false;
        this.guardarExpediente == false;
      }
    },
    async setSector() {
      const res = await this.getSectores();
      this.itemsSector = res;
    },
    async setTipoExp() {
      if (this.itemsConsulta) {
        const resp = await this.getTipoExpById(
          this.itemsConsulta.procTipoExpId
        );
        this.itemsTipoExp = resp;
      } else {
        const resp = await this.getTipoExpById(0);
        this.itemsTipoExp = resp;
      }
    },
    async setProceso() {
      const proc = await this.getProcesos();
      this.itemsProceso = proc;
    },
    newConfig() {
      this.formEditTitle = "Nueva configuración del proceso de replicación";
    },
    async setConfigRepli() {
      if (this.itemsConsulta) {
        this.formEditTitle = "Editar configuración del proceso de replicación";
        const res = await this.getProcesoReplica(this.idProcTipo);
        this.tiposExpSelected = res.mTipoExp_id;
        this.sectorSelected = res.mSector_id;
        this.procesoSelected = res.mProc_id;
        this.emisionRecepcion = res.emisionRecepcion;
      }
    },
    async saveEdit() {
      this.isLoadingBtn = true;
      let mSectorId;
      if (
        this.itemsConsulta &&
        this.itemsConsulta.mSectorId !== this.sectorSelected
      ) {
        mSectorId = this.sectorSelected.sectorId;
      } else if (this.itemsConsulta) {
        mSectorId = this.itemsConsulta.mSectorId;
      } else {
        mSectorId = this.sectorSelected.sectorId;
      }
      let procId;
      if (
        this.itemsConsulta &&
        this.itemsConsulta.procId !== this.procesoSelected
      ) {
        procId = this.procesoSelected.id;
      } else if (this.itemsConsulta) {
        procId = this.itemsConsulta.procId;
      } else {
        procId = this.procesoSelected.id;
      }
      let tipoExpe;
      if (
        this.itemsConsulta &&
        this.tiposExpSelected.tipoExpNom !== this.tiposExpSelected.mTipoExpNom
      ) {
        tipoExpe = this.tiposExpSelected.mTipoExpId;
      } else if (this.itemsConsulta) {
        tipoExpe = this.tiposExpSelected;
      } else {
        tipoExpe = this.tiposExpSelected.mTipoExpId;
      }

      const data = {
        mProcTipoExp_id: this.itemsConsulta?.procTipoExpId,
        mTipoExp_id: tipoExpe,
        mSector_id: mSectorId,
        mProc_id: procId,
        emisionRecepcion: this.emisionRecepcion
      };
      try {
        const res = await this.saveProcTipoExp(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          this.closeModal();
        }
      } catch (error) {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadNew");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
