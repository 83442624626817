var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-0 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('v-row',[_c('v-col',{attrs:{"cols":"7","md":"7"}},[_c('PageHeader',{attrs:{"title":_vm.title}})],1)],1),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-2",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{staticClass:"pr-4"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplyed}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsTipoExp,"label":"Tipo de expediente","item-text":"value","item-value":"id","outlined":"","dense":"","clearable":"","return-object":""},on:{"change":_vm.handleTipoExpChange},model:{value:(_vm.tiposExpSelected),callback:function ($$v) {_vm.tiposExpSelected=$$v},expression:"tiposExpSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"6","md":"4"}},[_c('v-autocomplete',{attrs:{"autocomplete":"on","items":_vm.itemsSector,"label":"Sector","item-text":"sectorNom","clearable":"","item-value":"sectorId","return-object":"","outlined":"","dense":""},on:{"change":_vm.handleSectorChange},model:{value:(_vm.sectorSelected),callback:function ($$v) {_vm.sectorSelected=$$v},expression:"sectorSelected"}})],1),_c('v-col',{staticClass:"pb-0 pt-5 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","elevation":"2","disabled":!_vm.isFormValid,"small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsConfigRepli,"item-key":"nroProceso","loading":_vm.loadingTable,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canConfig)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.goToConfigProceso()}}},[_vm._v(" Fecha contable ")])],1):_c('v-col',{attrs:{"cols":"2"}}),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","align":"end"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.openModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.editIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Editar configuración")])]):_vm._e(),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteProceso(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar configuración")])]):_vm._e()]}}],null,true)}),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleDelete,"maxWidth":'30%',"isLoading":_vm.loadingBtnDelete,"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)],1),(_vm.showIcon)?_c('v-col',{staticClass:"text-right pt-9 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":_vm.toggleHelp,"toggleIcon":function($event){return _vm.toggleIcon()}}})],1):_vm._e()],1),(_vm.openModalProc)?_c('v-dialog',{attrs:{"max-width":"38%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReloadNew($event)}},model:{value:(_vm.openModalProc),callback:function ($$v) {_vm.openModalProc=$$v},expression:"openModalProc"}},[_c('NuevaConfigProceso',{attrs:{"itemsConsulta":_vm.itemsConsulta},on:{"closeAndReloadNew":_vm.closeAndReloadNew}})],1):_vm._e(),(_vm.openModalConfigProc)?_c('v-dialog',{attrs:{"max-width":"35%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeAndReloadConfig($event)}},model:{value:(_vm.openModalConfigProc),callback:function ($$v) {_vm.openModalConfigProc=$$v},expression:"openModalConfigProc"}},[_c('ConfigProceso',{on:{"closeAndReloadConfig":_vm.closeAndReloadConfig}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }