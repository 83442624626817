<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ configTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveConfig()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <!-- fecha -->
              <v-select
                v-model="fechaSelected"
                :items="fechas"
                label="Fecha contable"
                item-text="value"
                item-value="id"
                :rules="rules.required"
                return-object
                outlined
                clearable
                dense
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined class="m-2" @click="closeModal">
          Cancelar
        </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          :loading="isLoadingBtn"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "ConfigProceso",
  directives: { mask },
  components: {
    GoBackBtn
  },
  data() {
    return {
      configTitle: "Fecha contable de proceso de replicación",
      rules: rules,
      fechaSelected: null,
      fechas: [
        { id: 1, value: "Fecha del comprobante" },
        { id: 2, value: "Fecha de replicación" },
        { id: 3, value: "Fecha 01/01/9999" }
      ],
      calendarIcon: enums.icons.CALENDAR,
      menuFechaComprobante: null,
      menuFechaReplicacion: null,
      fechaComprobante: new Date().toISOString().substr(0, 10),
      fechaComprobanteSelected: null,
      fechaReplicacionSelected: null,
      fechaReplicacion: new Date().toISOString().substr(0, 10),
      menuFecha: null,
      fechaSelected: null,
      fecha: null,
      isFormValid: false,
      isLoadingBtn: false,
      abConfigId: null
    };
  },
  created() {
    this.setConfig();
  },
  methods: {
    ...mapActions({
      getExpConfigReplica: "AdministracionSistema/getExpConfigReplica",
      postExpConfigReplica: "AdministracionSistema/postExpConfigReplica",
      saveConfigActualizacionAB: "prestadores/saveConfigActualizacionAB",
      setAlert: "user/setAlert"
    }),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    async setConfig() {
      const response = await this.getExpConfigReplica();
      this.fechaSelected = response.tipoFechaContableExpReplica;
    },
    async saveConfig() {
      this.isLoadingBtn = true;
      const data = {
        tipoFechaContableExpReplica:
          this.fechaSelected.value === "Fecha del comprobante"
            ? 1
            : this.fechaSelected.value === "Fecha de replicación"
            ? 2
            : 3
      };
      try {
        const res = await this.postExpConfigReplica(data);
        if (res.status === 200) {
          this.isFormValid = true;
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          this.isLoadingBtn = false;
          this.closeModal();
        }
      } catch {
        this.isLoadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadConfig");
    }
  }
};
</script>

<style scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
